import React from "react";
import Navbar from "../Header/Navbar";
import about from "../../img/about.jpg";
import Footer from "../Footer/Footer";
export const Mission = () => {
  return (
    <div>
      <div className="flex justify-center sm:p-10 p-4 ">
        <div className="w-[1000px] ">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit nostrum
            non in voluptates doloribus repellendus qui et, animi cum quisquam
            facilis, nulla, molestiae ex repellat ipsam accusamus delectus
            facere commodi! Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Ullam quibusdam reprehenderit cupiditate sapiente veritatis
            velit omnis odio officiis ad possimus. Soluta laudantium quas vitae
            illo obcaecati vel reprehenderit ex fugit.
          </p>
          <div className=" flex justify-center">
            <img
              src={about}
              className=" rounded-3xl py-10 max-h-[500px]"
              alt=""
            />
          </div>
        </div>
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  );
};
