import React from "react";
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaLinkedinIn,
  FaTwitterSquare,
  FaYoutube,
} from "react-icons/fa";
import wizard from "../../img/letrasWizard.png";
import { FaXTwitter } from "react-icons/fa6";
import background from "../../img/Background-header.jpg";
//@ts-ignore

interface SocialIconProps {
  icon: React.ElementType;
}

const SocialIcon: React.FC<SocialIconProps> = ({ icon: Icon }) => (
  <Icon className="social-icon hover:text-[#be95ff]" size={30} />
);

const Footer: React.FC = () => {
  const items = [
    {
      type: "icon",
      icon: FaFacebookSquare,
      url: "",
    },
    {
      type: "icon",
      icon: FaInstagram,
      url: "",
    },
    {
      type: "icon",
      icon: FaXTwitter,
      url: "",
    },
    {
      type: "icon",
      icon: FaLinkedinIn,
      url: "",
    },
    {
      type: "icon",
      icon: FaYoutube,
      url: "https://www.youtube.com/@WizardFootball17",
    },
    {
      type: "section",
      title: "Features",
      items: [
        { text: "App", link: "/wizardapp" },
        { text: "Product", link: "/product" },
        { text: "Home", link: "/" },
      ],
    },
    {
      type: "section",
      title: "Support",
      items: [
        { text: "Pricing", link: "/pricing" },
        { text: "Guides", link: "/" },
      ],
    },
    {
      type: "section",
      title: "Company",
      items: [{ text: "About us", link: "/company" }],
    },
    {
      type: "section",
      title: "Legal",
      items: [
        { text: "Legal", link: "/" },
        { text: "Cookies", link: "/" },
        { text: "Terms", link: "/" },
      ],
    },
  ];

  return (
    <div className=" flex justify-center bg-[#000300] overflow-x-hidden">
      <div className="max-w-[1400px]">
        <div className="sm:flex justify-between items-center">
          <img
            src={wizard}
            className="sm:mx-16 w-[400px] pt-16 pb-4 px-16 text-3xl lg:text-4xl xl:text-5xl font-bold text-[#be95ff]"
          />
          <div className="flex justify-between px-4 pt-12 md:w-[35%] my-6 text-gray-300">
            {items.map(
              (item, index) =>
                item.type === "icon" &&
                item.icon && ( // Check if item.icon is defined
                  <a target="_blank" href={item.url} rel="noreferrer">
                    <SocialIcon key={index} icon={item.icon} />
                  </a>
                )
            )}
          </div>
        </div>
        <div className="sm:ml-28">
          <hr />
        </div>
        <div className=" mx-auto py-6 px-8 sm:px-32  grid lg:grid-cols-3 gap-8 text-gray-300">
          <div>
            {/*         <h3 className="w-full text-3xl lg:text-4xl xl:text-5xl font-bold text-[#be95ff]">
          WIZARD
        </h3> */}
            {/* <img src={Logo} className="h-2 object-cover" /> */}
            <p className="py-7">
              Record football matches using cutting-edge AI technology.
              Experience unmatched clarity and precision as our AI-driven system
              captures every thrilling moment on the field. Elevate your game
              analysis!
            </p>
          </div>
          <div className="lg:col-span-2 flex justify-between mt-6">
            {items.map(
              (item, index) =>
                item.type === "section" && (
                  <div key={index}>
                    <h6 className="font-medium text-gray-100 text-xl">
                      {item.title}
                    </h6>
                    <ul>
                      {item.items &&
                        item.items.map((subItem, subIndex) => (
                          <li key={subIndex} className="py-2 text-sm">
                            <a
                              href={subItem.link}
                              className="hover:underline cursor-pointer"
                            >
                              {subItem.text}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
