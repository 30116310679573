import React, { useEffect } from "react";
import { Mission } from "../Components/Company/Mission";
import Navbar from "../Components/Header/Navbar";
import background from "../img/Background-header.jpg";
import { useNavigate } from "react-router-dom";
export const Company = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const password = localStorage.getItem("password");
    if (!password || password !== "elauro") {
      navigate("/password");
    }
  }, []);
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover", // Para que la imagen cubra todo el div
          backgroundPosition: "center", // Para centrar la imagen
          height: "450px", // Ajusta la altura según tus necesidades
          width: "100%", // Ajusta el ancho según tus necesidades
        }}
      >
        <Navbar />
        <div className="flex justify-center items-center pt-[300px]">
          <h2 className="text-5xl text-white z-20 absolute font-bold">ABOUT US</h2>
        </div>
      </div>
      <div>
        <Mission />
      </div>
    </div>
  );
};
