import React from "react";
import stream from "../img/handheldipadphoto.png";
import { FaPlay } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
export const Streaming = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex justify-center p-20">
        <h2 className="font-bold text-6xl text-center text-[#080F42]">
          LIVE MATCHES
        </h2>
      </div>
      <div className="flex justify-center p-10">
        <div className="w-[1200px]">
          <div className="sm:grid grid-cols-2">
            <div>
              <img
                src={stream}
                className=" rounded-3xl shadow-2xl max-h-[500px]"
                alt=""
              />
            </div>
            <div>
              <div className="sm:flex sm:pl-20 hidden ">
                <p
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  With the Wizard Device and our mobile app, you can watch live
                  football matches right from your phone. Experience the thrill
                  of the game in real-time, capturing every play and moment as
                  it happens. The combination of our advanced device and
                  user-friendly app ensures you stay connected to the action no
                  matter where you are.
                </p>
              </div>
              <div className="flex sm:pl-20 pt-4 ">
                <p
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  Once the match is over, upload your recordings to our platform
                  to review and analyze different actions that occurred during
                  the game. Our comprehensive system allows you to break down
                  each play, offering detailed insights into team and player
                  performance. Enhance your scouting and strategic planning with
                  easy access to every highlight and key moment from your
                  matches, Whatch our free Preview!.
                </p>
              </div>

              <div className="flex sm:pl-20 pt-4">
                <button
                  onClick={() => navigate("/preview/video")}
                  className="mt-8 flex items-center gap-2 justify-center border rounded-3xl p-2 w-48 bg-[#be95ff] text-white font-bold"
                >
                  <FaPlay /> Live preview
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
