import React from "react";

export const PricingTable = () => {
  return (
    <div className="pt-48  ">
      <div className="flex justify-center ">
              <div className="absolute">
                <div className="flex- justify-center z-20 ">
                  <h2 className="text-4xl font-bold text-white lg:text-5xl sm:text-5xl text-center">
                    Pricing &amp; Plans
                  </h2>
                </div>
                <p className="mt-4 text-lg leading-relaxed text-gray-200 text-center hidden sm:block ">
                  Amet minim mollit non deserunt ullam co est sit aliqua dolor
                  do amet sint. Velit officia consequat duis enim velit mollit.
                </p>
              </div>
        <section className="py-10 sm:py-16 lg:py-24 w-[1200px]">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 ">
            <div className="max-w-xl mx-auto text-center flex flex-col justify-center z-20">
            </div>
            <div className="hidden mt-16 lg:block bg-white p-10 rounded-3xl">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="py-8 pr-4"></th>

                    <th className="px-4 py-8 text-center">
                      <span className="text-base font-medium text-[#000346]">
                        {" "}
                        Free{" "}
                      </span>
                      <p className="mt-6 text-6xl font-bold text-[#000346]">
                        $0
                      </p>
                      <p className="mt-2 text-base font-normal text-[#000346]">
                        Per month
                      </p>
                    </th>

                    <th className="px-4 py-8 text-center">
                      <span className="text-base font-medium text-[#000346]">
                        {" "}
                        Team{" "}
                      </span>
                      <p className="mt-6 text-6xl font-bold text-[#000346]">
                        $99
                      </p>
                      <p className="mt-2 text-base font-normal text-[#000346]">
                        Per month
                      </p>
                    </th>

                    <th className="px-4 py-8 text-center bg-[#000346] rounded-t-xl">
                      <span className="px-4 py-2 text-base font-medium text-white bg-[#be95ff] rounded-full">
                        {" "}
                        Popular{" "}
                      </span>
                      <p className="mt-6 text-6xl font-bold text-[#be95ff]">
                        $150
                      </p>
                      <p className="mt-2 text-base font-normal text-[#be95ff]">
                        Per month
                      </p>
                    </th>

                    <th className="px-4 py-8 text-center">
                      <span className="text-base font-medium text-[#000346]">
                        {" "}
                        Enterprise{" "}
                      </span>
                      <p className="mt-6 text-6xl font-bold text-[#000346]">
                        $490
                      </p>
                      <p className="mt-2 text-base font-normal text-[#000346]">
                        Per month
                      </p>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="py-4 pr-4 font-medium border-b border-gray-200 text-[#000346]">
                      Devices number
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      01
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      10
                    </td>

                    <td className="px-4 py-4 text-center text-white bg-[#000346] border-b border-white ">
                      50
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      Unlimited
                    </td>
                  </tr>

                  <tr>
                    <td className="py-4 pr-4 font-medium border-b border-gray-200 text-[#000346]">
                      Server storage
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      100 GB
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      500 GB
                    </td>

                    <td className="px-4 py-4 text-center text-white bg-[#000346]  border-b border-white">
                      1 TB
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      Unlimited
                    </td>
                  </tr>

                  <tr>
                    <td className="py-4 pr-4 font-medium border-b border-gray-200 text-[#000346]">
                      Database
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      -
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      15
                    </td>

                    <td className="px-4 py-4 text-center text-white bg-[#000346]  border-b border-white">
                      Unlimited
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      Unlimited
                    </td>
                  </tr>

                  <tr>
                    <td className="py-4 pr-4 font-medium border-b border-gray-200 text-[#000346]">
                      Complete Statistics
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      -
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      <svg
                        className="w-5 h-5 mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#000346"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </td>

                    <td className="px-4 py-4 text-center text-white bg-[#000346]  border-b border-white">
                      <svg
                        className="w-5 h-5 mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentcolor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200">
                      <svg
                        className="w-5 h-5 mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#000346"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="py-4 pr-4 font-medium border-b border-gray-200 text-[#000346]">
                      App Account
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      -
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      -
                    </td>

                    <td className="px-4 py-4 text-center text-white bg-[#000346]  border-b border-white">
                      <svg
                        className="w-5 h-5 mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200">
                      <svg
                        className="w-5 h-5 mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#000346"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="py-4 pr-4 font-medium border-b border-gray-200 text-[#000346]">
                      Player Scouting Assistant
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      -
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      -
                    </td>

                    <td className="px-4 py-4 text-center text-white bg-[#000346]  border-b border-white">
                      <svg
                        className="w-5 h-5 mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200">
                      <svg
                        className="w-5 h-5 mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#000346"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="py-4 pr-4 font-medium border-b border-gray-200 text-[#000346]">
                      Personal Tripod
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      -
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      -
                    </td>

                    <td className="px-4 py-4 text-center text-white bg-[#000346]  border-b border-white">
                      <svg
                        className="w-5 h-5 mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200">
                      <svg
                        className="w-5 h-5 mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#000346"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="py-4 pr-4 font-medium border-b border-gray-200 text-[#000346]">
                      Server speed
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      -
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200 text-[#000346]">
                      -
                    </td>

                    <td className="px-4 py-4 text-center text-white bg-[#000346]  border-b border-white">
                      <svg
                        className="w-5 h-5 mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </td>

                    <td className="px-4 py-4 text-center border-b border-gray-200">
                      <svg
                        className="w-5 h-5 mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#000346"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="py-6 pr-4"></td>

                    <td className="px-4 py-6 text-center">
                      <a
                        href="#"
                        title=""
                        className="inline-flex items-center font-semibold text-[#be95ff] hover:text-[#000346]"
                      >
                        Get Started
                        <svg
                          className="w-4 h-4 ml-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </a>
                    </td>

                    <td className="px-4 py-6 text-center">
                      <a
                        href="#"
                        title=""
                        className="inline-flex items-center font-semibold text-[#be95ff] hover:text-[#000346]"
                      >
                        Get Started
                        <svg
                          className="w-4 h-4 ml-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </a>
                    </td>

                    <td className="px-4 py-6 text-center text-white bg-[#be95ff] rounded-b-xl">
                      <a
                        href="#"
                        title=""
                        className="inline-flex items-center font-semibold text-[#000346]"
                      >
                        Get Started
                        <svg
                          className="w-4 h-4 ml-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </a>
                    </td>

                    <td className="px-4 py-6 text-center">
                      <a
                        href="#"
                        title=""
                        className="inline-flex items-center font-semibold text-[#be95ff] hover:text-[#000346]"
                      >
                        Get Started
                        <svg
                          className="w-4 h-4 ml-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="block mt-12 border-t border-b border-gray-200 divide-y divide-gray-200 lg:hidden text-white">
            <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
              <div className="px-2 py-2">
                <span className="text-sm font-medium text-[#be95ff]">
                  {" "}
                  Free{" "}
                </span>
                <p className="mt-2 text-xl font-bold">$0</p>
                <span className="mt-1 text-sm font-normal text-gray-500">
                  {" "}
                  Per month{" "}
                </span>
              </div>

              <div className="px-2 py-2">
                <span className="text-sm font-medium text-[#be95ff]">
                  {" "}
                  Team{" "}
                </span>
                <p className="mt-2 text-xl font-bold">$99</p>
                <span className="mt-1 text-sm font-normal text-gray-500">
                  {" "}
                  Per month{" "}
                </span>
              </div>

              <div className="px-2 py-2">
                <span className="text-sm font-medium text-[#be95ff]">
                  {" "}
                  Popular{" "}
                </span>
                <p className="mt-2 text-xl font-bold">$150</p>
                <span className="mt-1 text-sm font-normal text-gray-500">
                  {" "}
                  Per month{" "}
                </span>
              </div>

              <div className="px-2 py-2">
                <span className="text-sm font-medium text-[#be95ff]">
                  {" "}
                  Enterprise{" "}
                </span>
                <p className="mt-2 text-xl font-bold">$490</p>
                <span className="mt-1 text-sm font-normal text-gray-500">
                  {" "}
                  Per month{" "}
                </span>
              </div>
            </div>

            <div className="px-2 py-4 sm:px-4">
              <p className="font-semibold">Website number</p>
            </div>

            <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
              <div className="px-2 py-2">01</div>

              <div className="px-2 py-2">10</div>

              <div className="px-2 py-2">100</div>

              <div className="px-2 py-2">Unlimited</div>
            </div>

            <div className="px-2 py-4 sm:px-4">
              <p className="font-semibold">Server storage</p>
            </div>

            <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
              <div className="px-2 py-2">100 GB</div>

              <div className="px-2 py-2">500 GB</div>

              <div className="px-2 py-2">1 TB</div>

              <div className="px-2 py-2">Unlimited</div>
            </div>

            <div className="px-2 py-4 sm:px-4">
              <p className="font-semibold">Database</p>
            </div>

            <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
              <div className="px-2 py-2">-</div>

              <div className="px-2 py-2">15</div>

              <div className="px-2 py-2">Unlimited</div>

              <div className="px-2 py-2">Unlimited</div>
            </div>

            <div className="px-2 py-4 sm:px-4">
              <p className="font-semibold">Unmetered bandwidth</p>
            </div>

            <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
              <div className="px-2 py-2">-</div>

              <div className="px-2 py-2">
                <svg
                  className="w-5 h-5 mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>

              <div className="px-2 py-2">
                <svg
                  className="w-5 h-5 mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>

              <div className="px-2 py-2">
                <svg
                  className="w-5 h-5 mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>

            <div className="px-2 py-4 sm:px-4">
              <p className="font-semibold">SSD Disk</p>
            </div>

            <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
              <div className="px-2 py-2">-</div>

              <div className="px-2 py-2">
                <svg
                  className="w-5 h-5 mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>

              <div className="px-2 py-2">
                <svg
                  className="w-5 h-5 mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>

              <div className="px-2 py-2">
                <svg
                  className="w-5 h-5 mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>

            <div className="px-2 py-4 sm:px-4">
              <p className="font-semibold">VCPUS Fontworld</p>
            </div>

            <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
              <div className="px-2 py-2">-</div>

              <div className="px-2 py-2">
                <svg
                  className="w-5 h-5 mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>

              <div className="px-2 py-2">
                <svg
                  className="w-5 h-5 mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>

              <div className="px-2 py-2">
                <svg
                  className="w-5 h-5 mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>

            <div className="px-2 py-4 sm:px-4">
              <p className="font-semibold">WordPress install</p>
            </div>

            <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
              <div className="px-2 py-2">-</div>

              <div className="px-2 py-2">
                <svg
                  className="w-5 h-5 mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>

              <div className="px-2 py-2">
                <svg
                  className="w-5 h-5 mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>

              <div className="px-2 py-2">
                <svg
                  className="w-5 h-5 mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>

            <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
              <div className="px-1 py-2 sm:px-4">
                <span className="text-sm font-medium text-[#be95ff]">
                  {" "}
                  Free{" "}
                </span>
                <p className="mt-2 text-xl font-bold">$0</p>
                <span className="mt-1 text-sm font-normal text-gray-500">
                  {" "}
                  Per month{" "}
                </span>
                <a
                  href="#"
                  title=""
                  className="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-[#be95ff] border border-transparent rounded-md hover:bg-blue-700"
                  role="button"
                >
                  {" "}
                  Get Started{" "}
                </a>
              </div>

              <div className="px-1 py-2 sm:px-4">
                <span className="text-sm font-medium text-[#be95ff]">
                  {" "}
                  Team{" "}
                </span>
                <p className="mt-2 text-xl font-bold">$99</p>
                <span className="mt-1 text-sm font-normal text-gray-500">
                  {" "}
                  Per month{" "}
                </span>
                <a
                  href="#"
                  title=""
                  className="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-[#be95ff] border border-transparent rounded-md hover:bg-blue-700"
                  role="button"
                >
                  {" "}
                  Get Started{" "}
                </a>
              </div>

              <div className="px-1 py-2 sm:px-4">
                <span className="text-sm font-medium text-[#be95ff]">
                  {" "}
                  Popular{" "}
                </span>
                <p className="mt-2 text-xl font-bold">$150</p>
                <span className="mt-1 text-sm font-normal text-gray-500">
                  {" "}
                  Per month{" "}
                </span>
                <a
                  href="#"
                  title=""
                  className="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-[#be95ff] border border-transparent rounded-md hover:bg-blue-700"
                  role="button"
                >
                  {" "}
                  Get Started{" "}
                </a>
              </div>

              <div className="px-1 pt-2 pb-4 sm:px-4">
                <span className="text-sm font-medium text-[#be95ff]">
                  {" "}
                  Enterprise{" "}
                </span>
                <p className="mt-2 text-xl font-bold">$490</p>
                <span className="mt-1 text-sm font-normal text-gray-500">
                  {" "}
                  Per month{" "}
                </span>
                <a
                  href="#"
                  title=""
                  className="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-[#be95ff] border border-transparent rounded-md hover:bg-blue-700"
                  role="button"
                >
                  {" "}
                  Get Started{" "}
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
