import React, { useEffect } from "react";
import Navbar from "../Components/Header/Navbar";
import { Title } from "../Components/Header/Title";
import { WizarPlatform } from "../Components/WizarPlatform";
import WizardDevice from "../Components/WizardDevice";
import Footer from "../Components/Footer/Footer";
import { PlayerReview } from "../Components/PlayerReview";
import { Streaming } from "../Components/Streaming";
import { useNavigate } from 'react-router-dom';

export const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const password = localStorage.getItem("password");
    if (!password || password !== "elauro") {
      navigate("/password");
    }
  }, []);
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div>
        <Title/>
      </div>
      <div>
        <WizarPlatform/>
      </div>
      <div>
        <WizardDevice/>
      </div>
      <div>
        <Streaming/>
      </div>
      <div>
        <PlayerReview/>
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  );
};
