import React, { useEffect } from "react";
import Navbar from "../Components/Header/Navbar";
import background from "../img/Background-header.jpg";
import ai from "../img/ai.avif";
import headerphoto from "../img/Video-Player-Mockup.png";
import laptop from "../img/laptop.png";
import livemockup from "../img/livemockup.png";
import Footer from "../Components/Footer/Footer";
import { useNavigate } from "react-router-dom";

export const WizardApp = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const password = localStorage.getItem("password");
    if (!password || password !== "elauro") {
      navigate("/password");
    }
  }, []);
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover", // Para que la imagen cubra todo el div
          backgroundPosition: "center", // Para centrar la imagen
          height: "100vh", // Ajusta la altura según tus necesidades
          width: "100%", // Ajusta el ancho según tus necesidades
        }}
      >
        <div className="flex justify-center p-10 pt-56 sm:pt-[300px] ">
          <div className=" md:flex justify-center gap-10 items-center z-10">
            <div className="max-w-[450px] text-white">
              <h1 className=" text-5xl font-bold">WIZARD PLATFORM</h1>
              <h2 className="pt-6 text-lg">
                The Wizard Platform is a state-of-the-art tool for scouting
                players and teams, offering live streaming and recorded matches.
                Perfect for talent scouts and coaches, it provides the insights
                needed to make informed decisions and deepen your understanding
                of the game.
              </h2>
              <div className="flex gap-10 font-bold">
                <button className="mt-8 border rounded-3xl p-2 w-40 bg-[#be95ff]">
                  Pricing
                </button>
                <a
                  onClick={() =>
                    (window.location.href = "https://wizardfootball.com")
                  }
                >
                  <button className="mt-8 border-2 rounded-3xl p-2 w-40 border-[#be95ff]">
                    Platform
                  </button>
                </a>
              </div>
            </div>
            <div className="md:w-[50%]">
              <img src={headerphoto} alt="" />
            </div>
          </div>
        </div>
        <div className=" absolute bottom-0 bg-gradient-to-t from-black to-transparent w-[100%] h-[300px] "></div>
      </div>
      <div>
        <div>
          <div className="flex justify-center p-10">
            <div className="w-[1200px]">
              <div className="sm:grid grid-cols-2 pb-10">
                <div>
                  <img
                    src={laptop}
                    className=" rounded-3xl shadow-2xl max-h-[500px]"
                    alt=""
                  />
                </div>
                <div>
                  <h2 className="font-bold text-3xl sm:pl-20 sm:text-left text-center pt-4 text-[#080F42]">
                    LIBRARY
                  </h2>
                  <br />
                  <div className="sm:flex sm:pl-20 hidden ">
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      The Wizard Platform offers seamless access to your
                      extensive match library, allowing you to view all recorded
                      games captured by your Wizard Device. With an intuitive
                      interface, you can easily browse through your collection,
                      search for specific matches, and watch recordings at your
                      convenience. This feature ensures that every moment is at
                      your fingertips, ready to be reviewed and analyzed
                      whenever needed.
                    </p>
                  </div>
                  <br />
                  <div className="sm:flex sm:pl-20 hidden ">
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      Accessing your match library on the Wizard Platform is
                      straightforward and user-friendly. Simply log in to your
                      account, navigate to the library section, and you'll find
                      a comprehensive list of all your recorded games. Whether
                      you're a coach analyzing team performance or a scout
                      reviewing player skills, the platform provides a
                      streamlined experience to help you gain valuable insights
                      from your recordings.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center p-10 bg-[#EFEFEF]">
            <div className="w-[1200px]">
              <div className="sm:grid grid-cols-2 pb-10 items-center">
                <div>
                  <h2 className="font-bold text-3xl sm:pl-20 sm:text-left text-center text-[#080F42]">
                    LIVE STREAMING
                  </h2>
                  <br />
                  <div className="sm:flex sm:pl-20 hidden ">
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      The Wizard Platform also allows you to stream live matches
                      directly from your Wizard Device. With this feature, you
                      can broadcast games in real-time, enabling coaches,
                      scouts, and fans to watch live action as it unfolds. The
                      platform ensures high-quality streaming, making it easy to
                      follow the game from anywhere, providing a seamless
                      experience for all users.
                    </p>
                  </div>
                </div>
                <img
                  src={livemockup}
                  className=" rounded-3xl sm:pl-20 "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center p-10">
            <div className="w-[1200px]">
              <div className="sm:grid grid-cols-2 pb-10">
                <div>
                  <img
                    src={ai}
                    className=" rounded-3xl shadow-2xl max-h-[500px]"
                    alt=""
                  />
                </div>
                <div>
                  <h2 className="font-bold text-3xl sm:pl-20 sm:text-left text-center pt-4 text-[#080F42]">
                    AI STATISTICS MODEL
                  </h2>
                  <br />
                  <div className="sm:flex sm:pl-20 hidden ">
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      The artificial intelligence implemented in the Wizard
                      Platform represents a groundbreaking innovation in the
                      analysis and presentation of football match statistics.
                      This advanced AI collects real-time data from key game
                      events, including goals, accurate passes, fouls, ball
                      possession, and many more detailed metrics. With its
                      ability to process large volumes of information, the AI
                      not only provides an instant and accurate view of the
                      performance of each player and the team overall but also
                      offers predictive analysis and recommendations based on
                      historical patterns and current trends.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
