import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { Home } from "./Screens/Home";
import { Company } from "./Screens/Company";
import { Pricing } from "./Screens/Pricing";
import PreviewVideo from "./Screens/PreviewVideo";
import { Product } from "./Screens/Product";
import { WizardApp } from "./Screens/WizardApp";
import { Elauro } from "./Screens/Elauro";
import { Football } from "./Screens/Football";

const router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "/password", element: <Elauro /> },
  { path: "/company", element: <Company /> },
  { path: "/pricing", element: <Pricing /> },
  { path: "/preview/video", element: <PreviewVideo /> },
  { path: "/product", element: <Product /> },
  { path: "/wizardapp", element: <WizardApp /> },
  { path: "/football", element: <Football /> },
]);

export default function Router() {
  return <RouterProvider router={router} />;
}
