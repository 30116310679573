import React from "react";
//@ts-ignore
import video from "../../img/caida.mp4";

export const DeviceResistance = () => {
  return (
    <div className="flex justify-center p-10">
      <div className="max-w-[1200px] sm:flex gap-10">
        <div>
          <video
            src={video}
            className="rounded-3xl"
            autoPlay
            muted
            loop
          ></video>
        </div>
        <div>
          <h2 className="font-semibold text-4xl text-center sm:text-left pb-4">
            Impact Resistance{" "}
          </h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat non
            totam quae ut necessitatibus nobis? Soluta cupiditate rerum officia
            quia labore esse aut eius est animi, ipsum at vitae maiores?
          </p>
        </div>
      </div>
    </div>
  );
};
