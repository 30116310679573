import React from "react";
//@ts-ignore

import image from "../../img/Renders_Wizard/render12.png";

export const Camera = () => {
  return (
    <div className="sm:flex justify-center p-10 bg-[#EFEFEF]">
      <div className="max-w-[1200px] sm:flex gap-10">
        <div>
          <h2 className="font-semibold text-4xl text-center sm:text-left pb-4">
            4K Panoramic Camera{" "}
          </h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat non
            totam quae ut necessitatibus nobis? Soluta cupiditate rerum officia
            quia labore esse aut eius est animi, ipsum at vitae maiores?
          </p>
        </div>
        <div>
          <img src={image} className="w-[1000px] rounded-3xl"></img>
        </div>
      </div>
    </div>
  );
};
