import React, { useState, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../img/wizard-logo.png";
export const Elauro = () => {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password === "elauro") {
      localStorage.setItem("password", password); // Guarda la contraseña en localStorage
      navigate("/"); // Redirige a la pantalla principal si el password es correcto
    } else {
      alert("Password incorrecto"); // Muestra un mensaje de alerta si el password es incorrecto
    }
  };

  return (
    <div className="h-[100vh] bg-[#be95ff] w-full flex flex-col items-center p-40 ">
      <div className="flex justify-center">
        <img src={logo} className="w-[300px]" alt="" />
      </div>
      <div className="flex justify-center items-center gap-8 ">
        <form onSubmit={handleSubmit}>
          <p>Password</p>
          <input type="password" className="w-[200px] rounded-2xl h-[50px] text-2xl p-4" value={password} onChange={handleChange} />

          <div className="flex justify-center">
          <button
            className=" h-12 mt-10 bg-[#080F42] text-white px-4 py-2 rounded flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            type="submit"
            >
            Submit
          </button>
              </div>
        </form>
      </div>
    </div>
  );
};
