import React, { useState } from 'react';
import Navbar from '../Components/Header/Navbar';
import { FootballTitle } from '../Components/Football/FootballTitle';
import Footer from '../Components/Footer/Footer';
import minimap from "../img/minimap.png";
import heatmap from "../img/heatmap.png";
import detection from "../img/detection.png";
import clips from "../img/clips.png";

const imageDetails = {
  minimap: {
    title: "MINIMAP",
    description: "The minimap feature provides an overview of the entire field, displaying player positions in real-time. This helps in analyzing team formations and player movements throughout the game."
  },
  heatmap: {
    title: "HEATMAP",
    description: "The heatmap feature highlights the areas of the field where the most activity occurs. This visual representation helps in identifying key zones and player tendencies."
  },
  detection: {
    title: "DETECTION",
    description: "The detection feature uses advanced AI to track player movements and actions. It helps in detailed analysis of individual player performance and team strategies."
  }
};

type ImageKey = keyof typeof imageDetails;

export const Football = () => {
  const [currentImage, setCurrentImage] = useState<ImageKey>('minimap');

  const handleImageChange = (imageKey: ImageKey) => {
    setCurrentImage(imageKey);
  };

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div>
        <FootballTitle />
      </div>
      <div>
        <div className="flex justify-center p-10">
          <div className="w-[1200px]">
            <div className="sm:grid grid-cols-2 pb-10">
              <div>
                <img
                  src={clips}
                  className="rounded-3xl shadow-2xl max-h-[500px]"
                  alt=""
                />
              </div>
              <div>
                <h2 className="font-bold text-3xl sm:pl-20 sm:text-left text-center sm:pt-0 pt-20 text-[#080F42]">
                  HIGHLIGHTS
                </h2>
                <br />
                <div className="sm:flex sm:pl-20">
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    The Wizard Platform offers seamless access to your
                    extensive match library, allowing you to view all recorded
                    games captured by your Wizard Device. With an intuitive
                    interface, you can easily browse through your collection,
                    search for specific matches, and watch recordings at your
                    convenience. This feature ensures that every moment is at
                    your fingertips, ready to be reviewed and analyzed whenever
                    needed.
                  </p>
                </div>
                <br />
                <div className="sm:flex sm:pl-20 hidden">
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    Accessing your match library on the Wizard Platform is
                    straightforward and user-friendly. Simply log in to your
                    account, navigate to the library section, and you'll find
                    a comprehensive list of all your recorded games. Whether
                    you're a coach analyzing team performance or a scout
                    reviewing player skills, the platform provides a
                    streamlined experience to help you gain valuable insights
                    from your recordings.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center p-10 bg-[#EFEFEF]">
        <div className="w-[1200px]">
          <div className="sm:grid grid-cols-2 pb-10">
            <div>
              <h2 className="font-bold text-3xl sm:text-left text-center sm:pt-4 pt-20 text-[#080F42]">
                {imageDetails[currentImage].title}
              </h2>
              <br />
              <div className="sm:flex min-h-32">
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {imageDetails[currentImage].description}
                </p>
              </div>
              <div className="sm:flex mb-8 sm:mb-0 justify-between mt-20 gap-4">
                <button
                  onClick={() => handleImageChange('heatmap')}
                  className={`border-2 rounded-3xl p-2 w-40 ${currentImage === 'heatmap' ? 'bg-[#080F42] text-white' : 'bg-white text-black'}`}
                >
                  Heatmap
                </button>
                <button
                  onClick={() => handleImageChange('detection')}
                  className={`border-2 rounded-3xl p-2 w-40 ${currentImage === 'detection' ? 'bg-[#080F42] text-white' : 'bg-white text-black'}`}
                >
                  Detection
                </button>
                <button
                  onClick={() => handleImageChange('minimap')}
                  className={`border-2 rounded-3xl p-2 w-40 ${currentImage === 'minimap' ? 'bg-[#080F42] text-white' : 'bg-white text-black'}`}
                >
                  Minimap
                </button>
              </div>
            </div>
            <div>
              <img src={currentImage === 'minimap' ? minimap : currentImage === 'heatmap' ? heatmap : detection} className="rounded-3xl max-h-[500px]" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
