import React, { useEffect } from "react";
import Navbar from "../Components/Header/Navbar";
import background from "../img/Background-header.jpg";
import Footer from "../Components/Footer/Footer";
import { PricingTable } from "../Components/Pricing/PricingTable";
import tripods from "../img/tripods.png";
import { useNavigate } from "react-router-dom";
export const Pricing = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const password = localStorage.getItem("password");
    if (!password || password !== "elauro") {
      navigate("/password");
    }
  }, []);
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover", // Para que la imagen cubra todo el div
          backgroundPosition: "center", // Para centrar la imagen
          minHeight: "100vh", // Ajusta la altura según tus necesidades
          width: "100%", // Ajusta el ancho según tus necesidades
        }}
      >
        <Navbar />
        <div className="p-4">
          <PricingTable />
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
