import React, { useEffect } from "react";
import Navbar from "../Components/Header/Navbar";
import { Title } from "../Components/Header/Title";
import background from "../img/Background-header.jpg"
import device from "../img/Renders_Wizard/render15.png"
import { DeviceResistance } from "../Components/Product/DeviceResistance";
import Footer from "../Components/Footer/Footer";
import { Camera } from "../Components/Product/Camera";
import { useNavigate } from "react-router-dom";
export const Product = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const password = localStorage.getItem("password");
    if (!password || password !== "elauro") {
      navigate("/password");
    }
  }, []);
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="sm:p-20 h-[65vh] sm:h-[100vh]"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover", // Para que la imagen cubra todo el div
        backgroundPosition: "center", // Para centrar la imagen
        width: "100%", // Ajusta el ancho según tus necesidades
        display:"flex",
        justifyContent:"center",

      }}
    >
        <img className="sm:pt-40 sm:h-auto pt-56  h-[500px]  " src={device} alt="" />
      </div>
      <div>
        <DeviceResistance/>
      </div>
      <div>
        <Camera/>
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  );
};
