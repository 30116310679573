import React from "react";
import device1 from "../img/device1.jpeg"; // Ajusta la ruta según sea necesario
import device2 from "../img/device2.jpeg";
import device3 from "../img/device3.jpeg";

const WizardDevice = () => {
  return (
    <div className="bg-[#EFEFEF] sm:p-20 p-4">
      <div className="sm:flex justify-center items-center ">
        <div className="sm:grid grid-cols-2 sm:w-[65%] gap-4 ">
          <div>
            <div className="p-4 bg-[#080F42] text-white rounded-3xl h-[47%]">
              <h2 className="font-semibold text-4xl text-center sm:text-left pb-4 ">
                Wizard device
              </h2>
              <p>
                Your ultimate tool for recording
                and streaming football matches. With advanced AI technology, captures every moment in high quality, ensuring
                you never miss a play.
              </p>
            </div>
            <div className="sm:flex gap-4 pt-4 hidden ">
              <img className="w-[49%] rounded-3xl " src={device1} alt="" />
              <img className="w-[49%] rounded-3xl " src={device2} alt="" />
            </div>
          </div>
          <div>
            <img
              className=" h-[auto] pt-4 sm:pt-0 rounded-3xl"
              src={device3}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WizardDevice;
