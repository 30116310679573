import React from "react";
import background from "../../img/Background-header.jpg";
import device from "../../img/Renders_Wizard/render2.png";
import { useNavigate } from "react-router-dom";
export const Title = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover", // Para que la imagen cubra todo el div
        backgroundPosition: "center", // Para centrar la imagen
        height: "100vh", // Ajusta la altura según tus necesidades
        width: "100%", // Ajusta el ancho según tus necesidades
      }}
    >
      <div className="flex justify-center p-10 pt-56 sm:pt-[300px] ">
        <div className=" md:flex justify-center gap-10 items-center z-10">
          <div className="max-w-[600px] text-white">
            <h1 className=" text-5xl font-bold">WIZARD </h1>
            <h2 className="pt-6 text-lg">
              Record football matches using cutting-edge AI technology.
              Experience unmatched clarity and precision as our AI-driven system
              captures every thrilling moment on the field. Elevate your game
              analysis!
            </h2>
            <div className="flex gap-10 font-bold">
              <button className="mt-8 border rounded-3xl p-2 w-40 bg-[#be95ff]">
                Join Wizard
              </button>
              <a onClick={()=> navigate('/product')}>
                <button className="mt-8 border-2 rounded-3xl p-2 w-40 border-[#be95ff]">
                  Product
                </button>
              </a>
            </div>
          </div>
          <div className="md:w-[40%] pt-40 sm:pt-0">
            <img src={device} alt="" />
          </div>
        </div>
      </div>
      <div className=" absolute bottom-0 bg-gradient-to-t from-black to-transparent w-[100%] h-[300px] "></div>
    </div>
  );
};
