import React, { useState } from "react";
import tomas from "../img/tomas.png";
import poxo from "../img/coach4.jpg";
import adri from "../img/coach3.jpg";
import coach1 from "../img/coach1.png";
import coach2 from "../img/coach2.png";
import { FaQuoteLeft, FaQuoteRight, FaChevronLeft, FaChevronRight } from "react-icons/fa";

const reviews = [
  {
    img: adri,
    text: "The Wizard Device is a game-changer! It seamlessly records and streams matches with incredible clarity. The AI-driven insights are a fantastic bonus for analyzing team performance.",
    author: "Adrián Márquez Valía",
    rol: "Player Agent",
  },
  {
    img: poxo,
    text: "I love how easy it is to use the Wizard Device. The mobile app is intuitive, and being able to watch live games on my phone is a huge plus. Uploading and reviewing matches on the platform is a breeze.",
    author: "Jose Enrique Ferrero",
    rol: "Player Agent",
  },
  {
    img: tomas,
    text: "The quality of the live streams is impressive. I appreciate the detailed statistics and insights available on the platform. The Wizard Device has definitely enhanced my viewing experience.",
    author: "Tomas Jakaitis",
    rol: "Football Player",
  },
  {
    img: coach1,
    text: "As a coach, the Wizard Device has become an essential tool. The ability to break down games and review specific actions helps in developing better strategies for our team.",
    author: "Juan Carlos García Fernández",
    rol: "Football Coach",
  },
  {
    img: coach2,
    text: "The Wizard Device and app combo is perfect for any football enthusiast. Watching live matches and then analyzing them on the platform is an unmatched experience. Highly recommended!",
    author: "Jose Carlos Mullor Mataix",
    rol: "Football Coach",
  },
];

export const PlayerReview = () => {
  const [selectedReviewIndex, setSelectedReviewIndex] = useState(0);

  const handlePrevious = () => {
    setSelectedReviewIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : reviews.length - 1));
  };

  const handleNext = () => {
    setSelectedReviewIndex((prevIndex) => (prevIndex < reviews.length - 1 ? prevIndex + 1 : 0));
  };

  const selectedReview = reviews[selectedReviewIndex];

  return (
    <div className="flex justify-center p-20 bg-[#EFEFEF] overflow-x-hidden">
      <div className="w-[1200px]">
        <div>
          <div className="sm:hidden">
            <h2 className="font-bold text-6xl text-center text-[#080F42]">
              REVIEWS
            </h2>
          </div>
          <div className="sm:flex justify-between hidden">
            {reviews.map((review, index) => (
              <img
                key={index}
                src={review.img}
                className={`rounded-3xl shadow-2xl max-h-[200px] w-[200px] object-cover cursor-pointer transition duration-300 ${
                  selectedReview === review ? "" : "grayscale"
                }`}
                alt={review.author}
                onClick={() => setSelectedReviewIndex(index)}
              />
            ))}
          </div>
          <div className="flex items-center">
            <button onClick={handlePrevious} className="p-2">
              <FaChevronLeft size={30} color="#080F42" />
            </button>
            <div className="flex flex-col items-center w-full">
              <div className="flex pt-20 px-10 text-center">
                <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <FaQuoteLeft color="#080F42" size={50} />
                  {selectedReview.text}
                  <FaQuoteRight color="#080F42" size={50} />
                </p>
              </div>
              <div className="flex flex-col items-center pt-6">
                <p className="font-bold">- {selectedReview.author}</p>
                <p>{selectedReview.rol}</p>
              </div>
            </div>
            <button onClick={handleNext} className="p-2">
              <FaChevronRight size={30} color="#080F42" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
