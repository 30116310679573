import React from "react";
import logo from "./logo.svg";
import "./App.css";
//@ts-ignore
import Router from "./Router";

const AppContainer = () => {
  return <Router />;
};

function App() {
  return <AppContainer />;
}

export default App;
