import React, { useState } from "react";
import screens from "../img/screens.png";
import { FaPlay } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";
import "../Styles/wizardplatform.css";

export const WizarPlatform = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [modal, setModal] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const openModal = () => {
    setModal(!modal);
  };

  const closeModal = () => {
    setModal(false);
  };

  return (
    <div className="bg-white">
      <div className="flex justify-center  p-10">
        <h2 className="font-bold text-6xl text-center text-[#080F42]">
          Wizard platform
        </h2>
        {modal && (
          <section className="modal__bg">
            <div className="modal__align">
              <div className="modal__content">
                <IoCloseOutline
                  className="modal__close"
                  aria-label="Close modal"
                  onClick={closeModal}
                />
                <div className="modal__video-align">
                  {videoLoading && (
                    <div className="modal__spinner">
                      <BiLoaderAlt className="modal__spinner-style" />
                    </div>
                  )}
                  <iframe
                    className="modal__video-style"
                    width="800"
                    height="500"
                    src="https://www.youtube.com/embed/smrGz38JR1Q?si=1ip4B3DfZkdIy2mw"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      <div className="sm:flex justify-center">
        <div className="sm:flex justify-center p-10 ">
          <div className="md:w-[40%]">
            <img src={screens} alt="" />
          </div>
          <div className=" md:flex justify-center gap-10 items-center">
            <div className="max-w-[600px] text-white">
              <h2 className="pt-6 text-lg text-[#080F42]">
                Discover our innovative product, featuring a comprehensive
                platform that offers in-depth statistics for teams and players.
                Scout talent, analyze performance, and watch football matches
                all in one place. Enhance your game insights with our advanced
                AI-driven technology.
              </h2>
              <div className="flex gap-10 font-bold">
                <button
                  onClick={openModal}
                  className="mt-8 flex items-center gap-2 justify-center border rounded-3xl p-2 w-48 bg-[#be95ff]"
                >
                  <FaPlay /> Play video
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
